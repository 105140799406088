import React, { useState } from "react";
import { Container, Row, Col, Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { FcGoogle } from "react-icons/fc";
import meeBotImage from "../../images/MeeBotImage.png";
import { Box, TextField } from "@mui/material";
import "../../styles/signup.css"; // Import your CSS file
import Axios from 'axios';
// import { GoogleLogin } from '@react-oauth/google';
//import {jwtDecode} from "jwt-decode"; // Correct import statement

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    nameError: "",
    emailError: "",
    phoneError: "",
    passwordError: "",
    confirmPasswordError: "",
    formSubmitted: false,
    formSuccess: false,
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nameError = validateName(formData.fullName);
    const emailError = validateEmail(formData.email);
    const phoneError = validatePhoneNo(formData.phone);
    const passwordError = validatePassword(formData.password);
    const confirmPasswordError = validateConfirmPassword(
      formData.confirmPassword
    );

    if (
      nameError ||
      emailError ||
      phoneError ||
      passwordError ||
      confirmPasswordError
    ) {
      setFormData({
        ...formData,
        nameError,
        emailError,
        phoneError,
        passwordError,
        confirmPasswordError,
        formSubmitted: true,
        formSuccess: false,
      });
    } else {
      try {
        const response = await Axios.post('https://api.mebot.live/signup',
                                          //'http://127.0.0.1:2000/signup',
                                           formData);
        if (response && response.data) {
          console.log(response.data); // Handle success message
          setFormData({
            ...formData,
            fullname: "",
            email: "",
            phone: "",
            password: "",
            confirmPassword: "",
            nameError: "",
            emailError: "",
            phoneError: "",
            passwordError: "",
            confirmPasswordError: "",
            formSubmitted: true,
            formSuccess: true,
          });
        }
        else {
          console.error('Error:', 'Response or data is undefined');
          setFormData({
            ...formData,
            formSubmitted: true,
            formSuccess: false,
          });
        }
      }
      catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message); // Log error details
        setFormData({
          ...formData,
          formSubmitted: true,
          formSuccess: false,
        });
      }
    }

    //   // Proceed with form submission
    //   console.log("Form submitted:", formData);
    //   setFormData({
    //     fullName: "",
    //     email: "",
    //     phone: "",
    //     password: "",
    //     confirmPassword: "",
    //     nameError: "",
    //     emailError: "",
    //     phoneError: "",
    //     passwordError: "",
    //     confirmPasswordError: "",
    //     formSubmitted: true,
    //     formSuccess: true,
    //   });
    // }
  };

  const validateName = (name) => {
    let error = "";
    if (!name.trim()) {
      error = "Name is required";
    } else if (name.length < 10 || name.length > 100) {
      error = "Name should be between 10 and 100 characters";
    }
    return error;
  };

  const validateEmail = (email) => {
    let error = "";
    if (!email.trim()) {
      error = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      error = "Email is invalid";
    }
    return error;
  };

  const validatePassword = (password) => {
    let error = "";
    if (!password.trim()) {
      error = "Password is required";
    } else if (!/^(?=.*[a-zA-Z])(?=.*\d).{8,20}$/.test(password.trim())) {
      error =
        "Password should be between 8 and 20 characters, and contain at least one letter and one number";
    }
    return error;
  };

  const validatePhoneNo = (phoneNo) => {
    let error = "";
    if (!phoneNo.trim()) {
      error = "Phone No is required";
    } else if (!/^\d{10}$/.test(phoneNo.trim())) {
      error = "Phone No is invalid";
    }
    return error;
  };

  const validateConfirmPassword = (confirmPassword) => {
    let error = "";
    if (!confirmPassword.trim()) {
      error = "Confirm Password is required";
    } else if (confirmPassword !== formData.password) {
      error = "Passwords do not match";
    }
    return error;
  };

  return (
    <div className="background-image">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="image-container p-4">
              <img
                src={meeBotImage}
                alt="MeBot"
                className="img-fluid"
                style={{ maxWidth: "95%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex flex-column justify-content-between align-items-center h-100">
              <div
                className="sign-up-form p-4 mb-2"
                style={{ borderRadius: "50px", maxWidth: "500px" }}
              >
                <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      id="fullName"
                      label="Full Name"
                      variant="outlined"
                      className="mb-3"
                      size="small"
                      value={formData.fullName}
                      onChange={handleChange}
                      error={!!formData.nameError}
                      helperText={formData.nameError}
                    />
                    <TextField
                      id="email"
                      label="Email Id"
                      variant="outlined"
                      className="mb-3"
                      size="small"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formData.emailError}
                      helperText={formData.emailError}
                    />
                    <TextField
                      id="phone"
                      label="Phone Number"
                      variant="outlined"
                      className="mb-3"
                      size="small"
                      value={formData.phone}
                      onChange={handleChange}
                      error={!!formData.phoneError}
                      helperText={formData.phoneError}
                    />

                    <TextField
                      id="password"
                      label="Password"
                      variant="outlined"
                      className="mb-3"
                      size="small"
                      type="password"
                      value={formData.password}
                      onChange={handleChange}
                      error={!!formData.passwordError}
                      helperText={formData.passwordError}
                    />
                    <TextField
                      id="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      className="mb-3"
                      size="small"
                      type="password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      error={!!formData.confirmPasswordError}
                      helperText={formData.confirmPasswordError}
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        type="submit"
                        style={{
                          backgroundColor: "#3C3C3C",
                          borderRadius: "25px",
                        }}
                      >
                        Sign Up
                      </Button>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="form-bottom d-flex flex-column align-items-center mt-2">
                <div className="d-flex flex-row align-items-center">
                  <hr /> {/* Horizontal line */}
                  <span style={{ margin: "0.5rem" }}>Or</span>
                  <hr /> {/* Horizontal line */}
                </div>

                {/*<GoogleLogin
                  onSuccess={(credentialResponse) => {
                    var credentialResponseDecoded = jwtDecode(credentialResponse.credential);
                    console.log(credentialResponseDecoded);
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                />*/}

                {/* <Button
                  variant="outline-primary"
                  className="google-sign-in-button"
                  style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
                > */}
                {/*<FcGoogle style={{ fontSize: "24px", marginRight: "0.5rem" }} />{" "}*/}
                {/* Increase the font size */}
                {/* Sign in with Google
                </Button> */}


                <p className="mt-2 mb-0">
                  Already have an account? <Link to="/signin">Login</Link>{" "}
                  {/* Link to the Login component */}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {formData.formSubmitted && !formData.formSuccess && (
        <Toast
          bg="danger"
          className="position-fixed bottom-0 end-0 m-3"
          autohide
          onClose={() =>
            setFormData({ ...formData, formSubmitted: false, formSuccess: false })
          }
        >
          <Toast.Body>Form submission failed. Please check the errors.</Toast.Body>
        </Toast>
      )}
      {formData.formSubmitted && formData.formSuccess && (
        <Toast
          bg="success"
          className="position-fixed bottom-0 end-0 m-3"
          autohide
          onClose={() =>
            setFormData({ ...formData, formSubmitted: false, formSuccess: false })
          }
        >
          <Toast.Body>Form submitted successfully!</Toast.Body>
        </Toast>
      )}
    </div>
  );
};

export default SignUpPage;
