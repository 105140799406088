
import './App.css';
import Nav from './pages/Navigation/nav';
import Footer from './pages/Footer/footer';
import Home from './pages/Home/home';
import ContactUs from './pages/ContactUs/contact';
import About from './pages/About/About';
import Signin from './pages/SignIn/signin';
import Signup from './pages/SignUp/signup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/Landing/landing';
import HealthcareBot from './components/HealthCare/General/HealthcareBot';
import ManufacturingBot from './components/Manufacturing/ManufacturingBot';
import { AuthProvider } from './components/Authentication/AuthContext'; // Assuming you have an AuthContext for authentication

function App() {
  return (
    <div className="App">
      <AuthProvider>
      <Router> {/* Wrap your application with BrowserRouter */}
        <Nav/> 
        <Routes> {/* Define your routes using Routes component */}
          <Route path="/" element={<><Home/><Footer /></>} />
          {/* <Route path="/" element={<><Demo/></>} /> */}
          <Route path="/about" element={<><About/><Footer /></>} />
          <Route path="/contact" element={<><ContactUs/><Footer /></>} />
          <Route path="/signin" element={<Signin/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/landing" element={<><LandingPage /></>} />
          <Route path="/HealthcareBot" element={<HealthcareBot />} />
          <Route path="/ManufacturingBot" element={<ManufacturingBot />} />
        </Routes>
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
