import React, { useState, useContext, createContext, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   // Check if the user is already authenticated in localStorage
  //   const authState = localStorage.getItem('isAuthenticated');
  //   if (authState) {
  //     setIsAuthenticated(true);
  //   }
  // }, []);

  const login = () => {
    setIsAuthenticated(true);
    // Store authentication state in localStorage
    localStorage.setItem('isAuthenticated', 'true');
  };

  const logout = () => {
    setIsAuthenticated(false);
    // Remove authentication state from localStorage
    localStorage.removeItem('isAuthenticated');
  };

  // Clear authentication state from localStorage when the application starts
  useEffect(() => {
    localStorage.removeItem('isAuthenticated');
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
