// Footer.js

import React from 'react';
import PSS_PNG from '../../images/PSS_PNG.png';
import { Link } from 'react-router-dom';
import { SlSocialInstagram, SlSocialTwitter, SlSocialFacebook, SlSocialLinkedin, SlSocialYoutube } from 'react-icons/sl';
import '../../styles/footer.css';

const Footer = () => {
  return (
    <footer id="footer">
      {/* Footer content goes here */}
      <div className="container footer-content">
        <div className="row">
          {/* 1st Column */}
          <div className="col-6 col-sm-4 d-flex justify-content-center mb-4">
            <img src={PSS_PNG} className='Footer_PSS' alt='PSS LOGO' />
          </div>
          {/* 2nd Column
          <div className="col-6 col-sm-2 mb-3">
            <h5>Templates</h5>
            <div>
              <Link to="#">Medical</Link><br />
              <Link to="#">Industrial</Link>
            </div>
          </div> */}
          {/* 3rd Column */}
          <div className="col-6 col-sm-2 mb-3">
            <h5>Company</h5>
            <div>
              <Link to="/">Home</Link><br />
              <Link to="/">Demo</Link><br />
              <Link to="/about">About Us</Link>
            </div>
          </div>
          {/* 4th Column */}
          <div className="col-6 col-sm-3 mb-3">
            <h5>Useful Links</h5>
            <div>
              News and Media<br />
              Terms and Conditions<br />
              Privacy Policy
            </div>
          </div>
          {/* 5th Column */}
          <div className="col-12 col-sm-3 mb-3">
            <h5>Contact Us</h5>
            <p>Get in touch! with us...</p>

            <div>
               Pushpanjali, Sector 13, Plot 123,<br/>
               Opp Sukhwani Oasis, Chikhli PCNTDA <br />
               Pune 412105 <br />
               <b>Email : </b>&nbsp;<a href="mailto:pssmeebot@gmail.com" style={{textDecoration:'none', color:'black'}}>pssmeebot@gmail.com</a><br />
               <b>Contact : </b>&nbsp;<a href="tel:+919960673724" style={{textDecoration:'none', color:'black'}}>+91 99606 73724</a>
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="container-fluid footer-bottom">
        <div className="row d-flex align-items-center justify-content-between">
          <div className="col-6">
            <p className="copyright">© Copyright <strong><a href="https://puresoftsolutions.in/">PureSoftSolutions</a> Software Pvt Ltd</strong>- All Rights Reserved</p>
          </div>
          <div className="col-6 text-center">
            {/* Social Links Here */}
            <a href="https://puresoftsolutions.in/" className="social-icon"><SlSocialInstagram size={20} color="#171717" /></a>
            <a href="https://puresoftsolutions.in/" className="social-icon"><SlSocialTwitter size={20} color="#171717" /></a>
            <a href="https://puresoftsolutions.in/" className="social-icon"><SlSocialFacebook size={20} color="#171717" /></a>
            <a href="https://puresoftsolutions.in/" className="social-icon"><SlSocialLinkedin size={20} color="#171717" /></a>
            <a href="https://puresoftsolutions.in/" className="social-icon"><SlSocialYoutube size={20} color="#171717" /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
