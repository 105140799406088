// About.js

import React from 'react';
import '../../styles/about.css'; // Import the CSS file for styling

const About = () => {
  return (
//     <div className="about-container  css-selector">
//       <div className="card">
//         <div className="card-content">
//           <div style={{padding:10}}><p>Welcome to MeBot! <br></br></p>
// <p>
// At MeBot, we're passionate about leveraging the latest advancements in artificial intelligence to revolutionize the way people work and access information. Our mission is to empower individuals and businesses with smart, intuitive solutions that enhance productivity and streamline processes.<br></br>
// </p><p>
// With a team of dedicated engineers and designers, we've developed MeBot, an AI-driven virtual assistant designed to cater to your specific needs. Whether you're seeking quick answers to your questions, looking for support documents, or searching for industry insights, MeBot is here to help.<br></br>
// </p><p>
// We believe in the power of simplicity and efficiency. That's why we've created MeBot to be easy to use and incredibly fast, so you can spend less time searching for information and more time getting things done.<br></br>
// </p><p>
// Join us on our journey to transform the way people work. Try MeBot today and experience the future of productivity.<br></br>
// </p><p>
// Welcome to the future of work. Welcome to MeBot.</p></div>
//         </div>
//       </div>
//     </div>
  <div className='about-container'>
    <div style={{padding:10}} className='rohan'><p>Welcome to MeBot! <br></br></p>
        <p>
        At MeBot, we're passionate about leveraging the latest advancements in artificial intelligence to revolutionize the way people work and access information. Our mission is to empower individuals and businesses with smart, intuitive solutions that enhance productivity and streamline processes.<br></br>
        </p><p>
        With a team of dedicated engineers and designers, we've developed MeBot, an AI-driven virtual assistant designed to cater to your specific needs. Whether you're seeking quick answers to your questions, looking for support documents, or searching for industry insights, MeBot is here to help.<br></br>
        </p><p>
        We believe in the power of simplicity and efficiency. That's why we've created MeBot to be easy to use and incredibly fast, so you can spend less time searching for information and more time getting things done.<br></br>
        </p><p>
        Join us on our journey to transform the way people work. Try MeBot today and experience the future of productivity.<br></br>
        </p><p>
        Welcome to the future of work. Welcome to MeBot.</p>
    </div>
  </div>
  );
};

export default About;
