// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/AboutUs.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* about.css */

.about-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); 
    background-color: inherit;
    background-size: cover;
    
  }
  .rohan{
    max-width: 800px;
    text-align: justify;
  }
  .card {
    background-color: rgba(240, 240, 240, 0.4); /* Add some opacity to the background color */
    border-radius: 8px;
    box-shadow: -12px 12px 27px 0px rgba(0, 0, 0, 0.75);
    padding: 24px;
    max-width: 800px;
    -webkit-backdrop-filter: blur(80px);
            backdrop-filter: blur(80px); /* Apply blur to the background */
  }
  
  .card-content {
    text-align: justify;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 2px;
  }
  
  .card-content p {
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 10px;
  }
  
  @media only screen and (max-width: 768px) {
    .rohan {
      font-size: 0.6em;
      padding: 5vw;
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/about.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,yDAA8C;IAC9C,yBAAyB;IACzB,sBAAsB;;EAExB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,0CAA0C,EAAE,6CAA6C;IACzF,kBAAkB;IAClB,mDAAmD;IACnD,aAAa;IACb,gBAAgB;IAChB,mCAA2B;YAA3B,2BAA2B,EAAE,iCAAiC;EAChE;;EAEA;IACE,mBAAmB;IACnB,mJAAmJ;IACnJ,YAAY;EACd;;EAEA;IACE,mBAAmB;IACnB,yCAAyC;IACzC,iBAAiB;EACnB;;EAEA;IACE;MACE,gBAAgB;MAChB,YAAY;IACd;EACF","sourcesContent":["/* about.css */\n\n.about-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 80vh;\n    background-image: url('../images/AboutUs.png'); \n    background-color: inherit;\n    background-size: cover;\n    \n  }\n  .rohan{\n    max-width: 800px;\n    text-align: justify;\n  }\n  .card {\n    background-color: rgba(240, 240, 240, 0.4); /* Add some opacity to the background color */\n    border-radius: 8px;\n    box-shadow: -12px 12px 27px 0px rgba(0, 0, 0, 0.75);\n    padding: 24px;\n    max-width: 800px;\n    backdrop-filter: blur(80px); /* Apply blur to the background */\n  }\n  \n  .card-content {\n    text-align: justify;\n    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n    padding: 2px;\n  }\n  \n  .card-content p {\n    text-align: justify;\n    font-family: Arial, Helvetica, sans-serif;\n    padding-top: 10px;\n  }\n  \n  @media only screen and (max-width: 768px) {\n    .rohan {\n      font-size: 0.6em;\n      padding: 5vw;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
