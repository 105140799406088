import React from 'react';
import "../../styles/typing.css"

const TypingIndicator = () => (
  <div className="typing-indicator">
    <b>Typing </b>&nbsp; &nbsp;
    <div className="dot-1"></div>
    <div className="dot-2"></div>
    <div className="dot-3"></div>
  </div>
);

export default TypingIndicator;
