const keywordPrompts = {
    // Existing healthcare keywords and prompts...
  
    // Pharmaceutical keywords and prompts...
    pharmacies: (originalInput) => `Provide information on pharmacies with ${originalInput}. Ensure the details are practical and accurate without dramatic claims.`,
    medical: (originalInput) => `Provide insights on medical related to ${originalInput}. Ensure the information is practical and accurate without dramatic claims.`,
    blood: (originalInput) => `Explain blood related issues and "${originalInput}". Ensure the information is practical and accurate without dramatic claims.`,
    
    // Medicines and Their Use
    "prescription drugs": (originalInput) => `Provide detailed information on prescription drugs, including uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    "over-the-counter medications": (originalInput) => `Explain over-the-counter medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    "generic medicines": (originalInput) => `Offer insights on generic medicines, their equivalency to brand-name drugs, and cost considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "brand-name drugs": (originalInput) => `Provide information on brand-name drugs, their uses, benefits, and cost considerations related to ${originalInput}. Ensure practical and accurate details.`,
    antibiotics: (originalInput) => `Explain the use of antibiotics, common types, dosages, and potential side effects related to ${originalInput}. Ensure practical and accurate details.`,
    antivirals: (originalInput) => `Offer insights on antiviral medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    "pain relievers": (originalInput) => `Provide information on pain relievers, including types (NSAIDs, acetaminophen, opioids), uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    "anti-inflammatory drugs": (originalInput) => `Explain anti-inflammatory drugs, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    antihypertensives: (originalInput) => `Provide detailed information on antihypertensive drugs, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    antidiabetics: (originalInput) => `Explain antidiabetic medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    antidepressants: (originalInput) => `Offer insights on antidepressant medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    antipsychotics: (originalInput) => `Provide information on antipsychotic medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    anticonvulsants: (originalInput) => `Explain anticonvulsant medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    bronchodilators: (originalInput) => `Offer insights on bronchodilator medications, their uses, dosages, and side effects related to ${originalInput}. Ensure practical and accurate details.`,
    
    // Medication Safety and Management
    "side effects": (originalInput) => `Provide detailed information on common and severe side effects of medications related to ${originalInput}. Ensure practical and accurate details.`,
    "drug interactions": (originalInput) => `Explain potential drug interactions with medications related to ${originalInput}. Ensure practical and accurate details.`,
    "medication adherence": (originalInput) => `Offer insights on the importance of medication adherence and tips related to ${originalInput}. Ensure practical and accurate details.`,
    "dosing guidelines": (originalInput) => `Provide information on dosing guidelines for medications related to ${originalInput}. Ensure practical and accurate details.`,
    "medication storage": (originalInput) => `Explain proper storage methods for medications related to ${originalInput}. Ensure practical and accurate details.`,
    
    // Special Populations
    "pediatric medications": (originalInput) => `Provide detailed information on pediatric medications, their dosages, and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "geriatric medications": (originalInput) => `Explain medications for the elderly, their dosages, and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "pregnancy and medications": (originalInput) => `Offer insights on the safety and use of medications during pregnancy related to ${originalInput}. Ensure practical and accurate details.`,
    "lactation and medications": (originalInput) => `Provide information on the safety and use of medications during lactation related to ${originalInput}. Ensure practical and accurate details.`,
    // Add more keywords and prompts as needed...
  };

  export const processUserInput = (input) => {
    // Split the input into words
    const words = input.toLowerCase().split(' ');
  
    // Check for keywords
    for (const word of words) {
      // Combine adjacent words to check for multi-word keywords
      const combinedWords = [word, words[words.indexOf(word) + 1]].join(' ');
  
      if (keywordPrompts[word]) {
        // If a single-word keyword is found, construct the new input with the original input included
        const newPrompt = keywordPrompts[word](input);
        return { processedInput: newPrompt, isModified: true };
      } else if (keywordPrompts[combinedWords]) {
        // If a multi-word keyword is found, construct the new input with the original input included
        const newPrompt = keywordPrompts[combinedWords](input);
        return { processedInput: newPrompt, isModified: true };
      }
    }
  
    // If no keywords are found, check if the input is unrelated to healthcare
    const pharmaceuticalKeywords = [
        "pharmacies", "medical", "blood", "prescription", "over-the-counter", "generic", "brand-name", 
        "antibiotics", "antivirals", "pain", "anti-inflammatory", "antihypertensives", "antidiabetics", 
        "antidepressants", "antipsychotics", "anticonvulsants", "bronchodilators", "side", "drug", 
        "medication", "dosing", "storage", "pediatric", "geriatric", "pregnancy", "lactation",
        "prescription drugs", "over-the-counter medications", "generic medicines", "brand-name drugs",
        "antihypertensives", "antidiabetics", "antidepressants", "antipsychotics", "anticonvulsants",
        "bronchodilators", "side effects", "drug interactions", "medication adherence", "dosing guidelines",
        "medication storage", "pediatric medications", "geriatric medications", "pregnancy and medications",
        "lactation and medications"
      ];
      
    const inputWords = input.toLowerCase().split(' ');
    console.log("Hello from pharma ");
    const isPharmaceuticalRelated = inputWords.some(word => pharmaceuticalKeywords.includes(word));
  
    if (!isPharmaceuticalRelated) {
      return { processedInput: `I'm sorry, I cannot provide information on that topic. Please ask health-related queries. ${input}`, isModified: true };
    }
  
    // If no keywords are found, append the generic message
    const newPrompt = `Provide accurate and practical pharmaceutical information related to the query. Ensure the details are proper and free from dramatic claims. ${input}`;
    return { processedInput: newPrompt, isModified: true };
  };
  
