import React, { useState } from "react";
import { Container, Row, Col, Button, Toast } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { FcGoogle } from "react-icons/fc";
import meeBotImage from "../../images/MeeBotImage.png";
import { Box, TextField } from "@mui/material";
//import { Toast } from "react-bootstrap";
import "../../styles/signin.css"; // Import your CSS file
import Axios from 'axios';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useAuth } from '../../components/Authentication/AuthContext'; // Assuming AuthContext provides authentication state and actions


const SignInPage = () => {
  const { login } = useAuth(); // Get login function from AuthContext
  const navigate = useNavigate(); // Initialize useNavigate

  const [formData, setFormData] = useState({
    formEmail: "",
    formPassword: "",
    emailError: "",
    passwordError: "",
    formSubmitted: false,
    formSuccess: false,
  });


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value, emailError: "", passwordError: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailError = validateEmail(formData.formEmail);
  const passwordError = validatePassword(formData.formPassword);

  setFormData({ ...formData, formSubmitted: true, formSuccess: true });

    if (emailError || passwordError) {
    setFormData({ ...formData, emailError, passwordError });
    return; // Stop execution if there are validation errors
    }

    try {
      const response = await Axios.post('https://api.mebot.live/signin',
                                        //'http://127.0.0.1:2000/signin', 
                                        {
        Email: formData.formEmail,
        Password: formData.formPassword,
      });
      if (response && response.data) {
        console.log(response.data); // Handle success message
        setFormData({
          ...formData,
          formSubmitted: true,
          formSuccess: true,
        });
        login(); // Update global isAuthenticated state to true
        console.log("login successfully");
        // setIsAuthenticated(true);
        // console.log("Authicated", isAuthenticated);
        // console.log("login successfully");
        navigate("/landing"); // Use navigate to navigate to "/landing"

      } else {
        console.error('Error:', 'Response or data is undefined');
        setFormData({
          ...formData,
          formSubmitted: true,
          formSuccess: false,
        });
      }
    } catch (error) {
      console.error('Error:', error.response.data); // Handle error message
      setFormData({
        ...formData,
        formSubmitted: true,
        formSuccess: false,
      });
    }
      



    // const emailError = validateEmail(formData.formEmail);
    // const passwordError = validatePassword(formData.formPassword);

    // if (emailError || passwordError) {
    //   setFormData({ ...formData, emailError, passwordError });
    // } else {
    //   console.log("Login details submitted:", formData);
    //   setFormData({
    //     ...formData,
    //     formEmail: "",
    //     formPassword: "",
    //     formSubmitted: true,
    //     formSuccess: true,
    //   });
    // }
  };

  const validateEmail = (formEmail) => {
    let error = "";
    if (!formEmail.trim()) {
      error = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formEmail)) {
      error = "Email is invalid";
    }
    return error;
  };

  const validatePassword = (formPassword) => {
    let error = "";
    if (!formPassword.trim()) {
      error = "Password is required";
    } else if (!/^(?=.*[a-zA-Z])(?=.*\d).{8,20}$/.test(formPassword.trim())) {
      error =
        "Password is between 8 and 20 characters, and contain at least one letter and one number";
    }
    return error;
  };

  return (
    <div className="background-image">
      <Container>
        <Row>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="image-container p-4">
              <img
                src={meeBotImage}
                alt="MeBot"
                className="img-fluid"
                style={{ maxWidth: "95%", height: "auto" }}
              />
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
              <div
                className="sign-up-form p-4 mb-2"
                style={{ borderRadius: "50px", maxWidth: "500px" }}
              >
                <div
                  className="p-4"
                  style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  <Box
                    component="form"
                    sx={{
                      "& > :not(style)": { m: 1, width: "100%" },
                    }}
                    noValidate
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <TextField
                      id="formEmail"
                      label="Email Id"
                      variant="outlined"
                      className="mb-4"
                      size="small"
                      value={formData.formEmail}
                      onChange={handleChange}
                      error={!!formData.emailError}
                      helperText={formData.emailError}
                    />
                    <TextField
                      id="formPassword"
                      label="Password"
                      variant="outlined"
                      className="mb-4"
                      size="small"
                      type="password"
                      value={formData.formPassword}
                      onChange={handleChange}
                      error={!!formData.passwordError}
                      helperText={formData.passwordError}
                    />
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Link to="/forgot-password" className="text-danger">Forgot Password?</Link>
                      <Button
                        variant="primary"
                        type="submit"
                        style={{
                          backgroundColor: "#3C3C3C",
                          borderRadius: "25px",
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                        }}
                      >
                        SIGN IN
                      </Button>
                    </div>
                  </Box>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {formData.formSubmitted && !formData.formSuccess && (
        <Toast
          bg="danger"
          className="position-fixed bottom-0 end-0 m-3"
          autohide
          onClose={() =>
            setFormData({ ...formData, formSubmitted: false, formSuccess: false })
          }
        >
          <Toast.Body>Invalid credential</Toast.Body>
        </Toast>
      )}
      {formData.formSubmitted && formData.formSuccess && (
        <Toast
          bg="success"
          className="position-fixed bottom-0 end-0 m-3"
          // autohide
          // delay={6000} 
          onClose={() =>
            setFormData({ ...formData, formSubmitted: false, formSuccess: false })
          }
        >
          <Toast.Body>Login successfully!</Toast.Body>
        </Toast>
      )}
    </div>
  );
};

export default SignInPage;
