

import React, {useState } from 'react';
import '../../styles/contact.css';
import contactImage from '../../images/ContactUs.gif';
import emailjs from '@emailjs/browser'
import {toast} from 'react-toastify';
import { Button } from 'react-bootstrap';


const ContactUs = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    comment: ''
  });

  const [ loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
              'service_x8bjlh3',
              'template_1sqc3dh',
              {
                from_name: form.name,
                to_name:"Pankaj",
                from_email:form.email,
                to_email:'pvmehta9511pm@gmail.com', //change mail point
                message:form.comment,
              },
              'AP8yU4dWDrh72v0ZL' 
    ).then(
      () =>{
        setLoading(false);
        toast.success("Thank You");
        console.log("send");
        setForm({name:"",email:"",comment:""});
      },
      (error)=>{
        setLoading(false);
        console.error(error);
        toast.error("Something went wrong.");
      }
    );   
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-card">
        <div className="contact-us-image">
          <img src={contactImage} alt="Contact Us" />
        </div>
        <div className="contact-us-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit} autoComplete="true">
            <input type="text" name="name" value={form.name} onChange={handleChange} placeholder="Name" required />
            <input type="email" name="email" value={form.email} onChange={handleChange} placeholder="Email" required />
            <small>We never share your email with anyone</small>
            <textarea name="comment" value={form.comment} onChange={handleChange} placeholder="add your message..." required /><br />
            <Button type="submit">{loading ? 'Sending...':'Send'}</Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
