import React, { useState } from 'react';
import img1 from '../../images/openChat.png';
import Body from '../HomeBody/body';
import ChatApp from '../../components/ChatAppHome/ChatApp';
import '../../styles/styles.css';

const Home = () => {
  const [showChat, setShowChat] = useState(false);

  const toggleChat = () => {
    setShowChat(!showChat);
  };

  return (
    <div>
      <Body />
      <img
        src={showChat ? {} : img1} // Use different images based on showChat state
        alt=""
        onClick={toggleChat}
        className={`chat-toggle-button ${showChat ? 'chat-toggle-button-red' : 'chat-toggle-button-green'}`}
      />
      {showChat && <ChatApp setShowChat={setShowChat} />}
    </div>
  );
};

export default Home;
