const keywordPrompts = {
  hospitals: (originalInput) => `Provide comprehensive information about hospital related to ${originalInput} Ensure accurate and practical details without dramatic claims.`,
  clinics: (originalInput) => `Provide information on clinics on ${originalInput} Ensure accurate and practical details without dramatic claims.`,
  nursing: (originalInput) => `Offer detailed insights on nursing in healthcare about ${originalInput} Ensure the information is practical and useful without dramatic claims.`,
  inpatient: (originalInput) => `Give an overview of inpatient care and ${originalInput} Include practical and useful information without dramatic claims.`,
  birth: (originalInput) => `Offer detailed insights on birth in healthcare, patient care, and important considerations. ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  hospices: (originalInput) => `Explain hospice care and ${originalInput} Ensure the information is practical and useful without dramatic claims.`,
  urgent: (originalInput) => `Offer detailed insights on urgent care for the  ${originalInput} in health Ensure the information is practical and accurate without dramatic claims.`,
  intermediate: (originalInput) => `Explain intermediate care and key considerations for ${originalInput} Ensure the information is practical and useful without dramatic claims.`,
  dialysis: (originalInput) => `Explain the process of dialysis and provide information on ${originalInput} Ensure the information is accurate and helpful without dramatic claims.`,
  
  physician: (originalInput) => `Give an overview of physician roles and patient care procedures, ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  health: (originalInput) => `Provide comprehensive information about health professionals and patient care related to ${originalInput} Ensure accurate and practical details without dramatic claims.`,
  surgical: (originalInput) => `Explain the role of surgical in healthcare related to ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  radiology: (originalInput) => `Provide detailed information on radiology for ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  optometry: (originalInput) => `Provide information on optometry, including eye care, common conditions, and treatments with considering ${originalInput} Ensure accurate and practical details without dramatic claims.`,
  
  primary: (originalInput) => `Offer insights on primary care for ${originalInput} Ensure the information is practical and useful without dramatic claims.`,
  mental: (originalInput) => `Give advice on mental health, treatments, and  ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  cardiovascular: (originalInput) => `Provide comprehensive information on cardiovascular care ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  emergency: (originalInput) => `Provide detailed information on emergency situation and care for  ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  social: (originalInput) => `Give an overview of social care, including types of services, patient care, and important considerations. ${originalInput} Ensure the information is practical and useful without dramatic claims.`,
  
  pharmacies: (originalInput) => `Provide information on pharmacies with ${originalInput} Ensure the details are practical and accurate without dramatic claims.`,
  medical: (originalInput) => `Provide insights on medical related on ${originalInput} Ensure the information is practical and accurate without dramatic claims.`,
  blood: (originalInput) => `Explain blood related issues and "${originalInput}" Ensure the information is practical and accurate without dramatic claims.`,
  
  dentists: (originalInput) => `Provide information on dental care, including preventive measures with ${originalInput} Ensure the details are practical and accurate without dramatic claims.`,
  
  diabetes: (originalInput) => `Provide detailed information with medicines on managing diabetes, including diet, medication, and lifestyle changes. ${originalInput} Give proper results without dramatic claims.`,
  hypertension: (originalInput) => `Give advice on controlling hypertension through diet, exercise, and medication. Also suggest medicines. ${originalInput} Make sure to include realistic recommendations without dramatic claims.`,
  headache: (originalInput) => `Suggest basic medicines and ways to reduce it. ${originalInput} Make sure to include realistic recommendations. Don't write I am not a doctor.`
};

export const processUserInput = (input) => {
  // Split the input into words
  const words = input.toLowerCase().split(' ');

  // Check for keywords
  for (const word of words) {
    if (keywordPrompts[word]) {
      // If a keyword is found, construct the new input with the original input included
      const newPrompt = keywordPrompts[word](input);
      return { processedInput: newPrompt, isModified: true };
    }
  }

  // If no keywords are found, check if the input is unrelated to healthcare
  const healthcareKeywords = [
    "hospitals", "clinics", "nursing", "inpatient", "birth", "hospices", "urgent", "intermediate",
    "dialysis", "physician", "health", "surgical", "radiology", "optometry", "primary", "mental",
    "cardiovascular", "emergency", "social", "pharmacies", "medical", "blood", "dentists", "diabetes",
    "hypertension", "headache","medicine"
  ];
  const inputWords = input.toLowerCase().split(' ');

  const isHealthcareRelated = inputWords.some(word => healthcareKeywords.includes(word));

  if (!isHealthcareRelated) {
    return { processedInput: `I'm sorry, I cannot provide information on that topic. ask me only health related queries  ${input}`, isModified: true };
  }

  // If no keywords are found, append the generic message
  const newPrompt = `Provide accurate and practical healthcare information related to the query. Ensure the details are proper and free from dramatic claims. ${input}`;
  return { processedInput: newPrompt, isModified: true };
};
