import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
//import { FaRegWindowClose } from "react-icons/fa";
import TypingIndicator from '../TypingIndicator/typingindicator';
import trainingData from '../../assets/TrainingData/trainingData.json'; // Check the path and adjust if necessary
import '../../styles/ChatApp.css'
import botLogo from '../../images/newLogo.png';
//import { useNavigate } from 'react-router-dom';
import onlinelogo from '../../images/onlinelogo.png';
import { LuSendHorizonal } from "react-icons/lu";

const ManufacturingBot = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState(() => {
   const storedMessages = sessionStorage.getItem('manufacturingChatMessages');
     return storedMessages ? JSON.parse(storedMessages) : [];
  });
  
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const welcomeMessageSent = sessionStorage.getItem('welcomeMessageSent') === 'true';
    const initialResponseSent = sessionStorage.getItem('initialResponseSent') === 'true';

    if (!welcomeMessageSent) {
      const delayedWelcomeMessage = setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: 'Welcome to Manufacturer Bot!' }
        ]);
        sessionStorage.setItem('welcomeMessageSent', 'true');

        // Delay the initial response to show right after the welcome message
        const delayedInitialResponse = setTimeout(() => {
          fetchDataFromAPI('i want you to work as a Manufacturer domain expert and generate synthetic information for queries which does not have data', false);
          sessionStorage.setItem('initialResponseSent', 'true');
        }, 500); // Adjust the delay as needed

        return () => clearTimeout(delayedInitialResponse);
      }, 1000);

      return () => clearTimeout(delayedWelcomeMessage);
    }

    if (!initialResponseSent && welcomeMessageSent) {
      fetchDataFromAPI('i want you to work as a Manufacturer domain expert and generate synthetic information for queries which does not have data', false);
      sessionStorage.setItem('initialResponseSent', 'true');
    }
  }, []); // Empty dependency array to run only once

  useEffect(() => {
    sessionStorage.setItem('manufacturingChatMessages', JSON.stringify(messages));
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) {
      console.error('Input value is empty');
      return;
    }
    
    const userInput = inputValue.trim();
    const processedInput = `${userInput} generate synthetic data`;
    //console.log('api url', process.env.REACT_APP_API_Manufacturer_URL);
    setLoading(true);

    // Update messages state to show the user input without the appended text
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: 'user', content: userInput }
    ]);

    // Check if user input matches any keyword in the training data
    const matchedKeyword = trainingData.find(
      (item) => item.input.toLowerCase() === processedInput.toLowerCase()
    );
    if (matchedKeyword) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: matchedKeyword.response }
      ]);
      setInputValue('');
      setLoading(false);
    } else {
      // If no keyword match, fetch data from the API
      fetchDataFromAPI(processedInput);
    }
  };

  const fetchDataFromAPI = async (input, shouldDisplayInput = true) => {
    let data = {
      input: {
        task: input
      },
      config: {},
      kwargs: {}
    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_MEBOT_URL,
      //url: 'http://127.0.0.1:2000/chain/invoke',
      //url: 'http://3.134.54.81:2000/chain/invoke',
      //url: 'http://api.mebot.live/chain/invoke',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    };
    try {
      const response = await axios.request(config);
      let extractedData = response.data.output;
      const colonIndex = extractedData.indexOf(':');
      if (colonIndex !== -1) {
        extractedData = extractedData.split(':').slice(2).join(':').trim();
      } else {
        const newlineIndex = extractedData.indexOf('\n');
        if (newlineIndex !== -1) {
          extractedData = extractedData.slice(newlineIndex + 1).trim();
        }
      }
      // Apply formatting
      extractedData = extractedData
        .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold text between **
        .replace(/\n/g, '<br>'); // Replace \n with <br> for new lines
  
      if (shouldDisplayInput) {
        setMessages((prevMessages)=>[
          ...prevMessages,
          { role: 'assistant', content: extractedData }
        ]);
      }
      setInputValue('');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


 // const navigate = useNavigate();

  // const closewin = () => {
  //   navigate('/');
  // };

  // useEffect(() => {
  //   sessionStorage.setItem('manufacturingChatMessages', JSON.stringify(messages));
  //   scrollToBottom();
  // }, [messages]);

  // useEffect(() => {
  //   window.addEventListener('beforeunload', clearChatHistory);
  //   return () => {
  //     window.removeEventListener('beforeunload', clearChatHistory);
  //   };
  // }, []);

  // const clearChatHistory = () => {
  //   sessionStorage.removeItem('manufacturingChatMessages');
  // };

  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  // };

  // const handleClose = () => {
  //   // Logic to close the chat window
  //   console.log('Manufacturing Chat window closed');
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (!inputValue.trim()) {
  //     console.error('Input value is empty');
  //     return;
  //   }
  //   setLoading(true);

  //   // Check if user input matches any keyword in the training data
  //   const matchedKeyword = trainingData.find(
  //     (item) => item.input.toLowerCase() === inputValue.toLowerCase()
  //   );
  //   if (matchedKeyword) {
  //     setMessages([
  //       ...messages,
  //       { role: 'user', content: inputValue },
  //       { role: 'assistant', content: matchedKeyword.response }
  //     ]);
  //     setInputValue('');
  //     setLoading(false);
  //   } else {
  //     // If no keyword match, fetch data from the API
  //     fetchDataFromAPI(inputValue);
  //   }
  // };

  // const fetchDataFromAPI = async (input) => {
  //   let data = {
  //     input: {
  //       task: input
  //     },
  //     config: {},
  //     kwargs: {}
  //   };
  //   let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: 'http://3.134.54.81:5000/chain/invoke',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: JSON.stringify(data)
  //   };
  //   try {
  //     const response = await axios.request(config);
  //     var parts = response.data.output.split(':');
  //     var extractedData = parts.slice(2).join(':').trim();
  //     setMessages([
  //       ...messages,
  //       { role: 'user', content: input },
  //       { role: 'assistant', content: extractedData }
  //     ]);
  //     setInputValue('');
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  return (
    <div className="chat-app" style={{backgroundColor: "lightblue", color: 'black',  height: "500px", maxWidth: "900px", margin: "0 auto" }}>
      <div id="Botheader">
        <div className="header-left">
          <img src={botLogo} alt="Bot Logo" className="bot-logo" />
          <div className="company">
            <div className="header" style={{ fontSize: '20px', marginLeft: '10px'}}>Manufacturer Bot</div>
            <div className="status">
              <span> <img src={onlinelogo} alt="online Logo" style={{ width: '10px', height: '10px'}}  /> </span>
              Online
            </div>
          </div>
        </div>
        {/* <div className="header-right">
          <div className="close" onClick={handleClose}>
            <FaRegWindowClose className="close-icon"  onClick={closewin} style={{ color: 'red' }} />
          </div>
        </div> */}
      </div>

      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.role === 'user' ? (
              <div className="message-header message-right">You :</div>
            ) : (
              <div className="message-header message-left">Bot :</div>
            )}
            {message.role === 'user' ? (
              <div className="user-message message-right">{message.content}</div>
            ) : (
              <div className="assistant-message message-left" dangerouslySetInnerHTML={{ __html: message.content }}></div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div>
        {loading && <TypingIndicator />}
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            className="input-field"
            autoFocus
          />
          <button type="submit" className="send-button">
            < LuSendHorizonal />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ManufacturingBot;