import React, { useState } from 'react';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../../images/newLogo.png';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../components/Authentication/AuthContext'; // Assuming AuthContext provides authentication state and actions

function App() {
  const { isAuthenticated, logout } = useAuth(); // Use authentication context
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const hideLinks = location.pathname === '/landing'; // Check if the current path is the landing page

  return (
    <Navbar bg="inherit" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <div className="d-flex align-items-center">
            <img
              alt=""
              src={logo}
              width="auto"
              height="30"
              className="d-inline-block align-top mr-3"
              style={{ paddingRight: '10px' }}
            />
            <h1 className="ml-3 mb-0" style={{ fontFamily: 'Arial Black' }}>MeBot</h1>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleMenuToggle} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="navi ms-auto justify-content-end flex container lg:center lg:py-2 flex-wrap items-center justify-between mx-auto px-4 py-2 ">
            {!hideLinks && (
              <>
                <Link to="/" className="nav-link d-lg-flex" style={{ fontSize: "10px", marginRight: "10px", fontWeight: "bold", color: "black", textDecoration: "none", alignItems: "center" }}>HOME</Link>
                <Link to="/about" className="nav-link d-lg-flex" style={{ fontSize: "10px", marginRight: "10px", fontWeight: "bold", color: "black", textDecoration: "none", alignItems: "center" }}>ABOUT US</Link>
                <Link to="/contact" className="nav-link d-lg-flex" style={{ marginRight: "10px", fontSize: "10px", fontWeight: "bold", color: "black", textDecoration: "none", alignItems: "center" }}>CONTACT US</Link>
              </>
            )}
            {isAuthenticated ? (
              <Link to="/" className="nav-link d-lg-flex">
                <Button onClick={logout} style={{ marginRight: "10px", fontSize: "10px", fontWeight: "bold", color: "black", textDecoration: "none", alignItems: "center" }}>Logout</Button>
              </Link>
            ) : (
              <div className="d-flex">
                {!isAuthenticated && (
                  <>
                    <Link to="/signin" className="nav-link" style={{ fontSize: "10px", fontWeight: "bold", color: "black" }}>
                      <Button variant="outline" className="btn btn-outline-info py-2 px-3" style={{ fontSize: "10px", fontWeight: "bold" }}>SIGN IN</Button>
                    </Link>
                    <Link to="/signup" className="nav-link" style={{ color: "black", textDecoration: "none" }}>
                      <Button variant="dark" className="rounded-pill py-2 px-3" style={{ fontSize: "10px", fontWeight: "bold" }}>SIGN UP</Button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default App;
