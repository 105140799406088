import React from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../styles/body.css'
import playIcon from '../../images/play.png';
import bodyLogo from '../../images/newLogo.png';

function Body() {
  const navigate = useNavigate();

  const handleSendRequest = () => {
    navigate('/contact');
  };

  return (
    <div>
      <div className="home-banner">
       <div className='body-cnt'>
       <h1 className="home-text16">Elevate productivity</h1>
        <h1 className="home-text19"><span className='text-gradient'>MeBot</span> Your AI Virtual Assistant</h1>
       </div>
        <img className='bodyImage' src={bodyLogo} alt='MeBot logo'/>
        <div>
        <div className='BottomArea mb-3'>
        <span className="home-text17">
          <span className="home-text18">MeBot's AI quickly finds answers from support docs.<br/> Just ask in our app!</span>
        </span>
        </div>
        <div className="home-container1 mb-2">
          <Button variant="dark" className="home-button button rounded-pill" onClick={handleSendRequest} style={{marginRight:"20px"}}>SEND YOUR REQUEST</Button>
          <Button varient="outline-primary" className="rounded-pill home-button1 button" style={{ backgroundColor: 'transparent', borderColor: 'black', color: 'black' }} ><img src={playIcon} alt='V' style={{marginLeft:'5px',marginRight:'5px',height:'20px'}}></img>WATCH VIDEO</Button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Body
