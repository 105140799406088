const keywordPrompts = {
    // General Operation Theater related keywords and prompts
    surgery: (originalInput) => `Provide detailed information on surgery related to ${originalInput}. Ensure the details are practical and accurate without dramatic claims.`,
    "operation room": (originalInput) => `Offer insights on operation rooms, including setup, hygiene protocols, and related ${originalInput}. Ensure practical and accurate details.`,
    anesthesia: (originalInput) => `Explain the types of anesthesia, their uses, and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical instruments": (originalInput) => `Provide information on surgical instruments used in operations related to ${originalInput}. Ensure practical and accurate details.`,
    "pre-operative care": (originalInput) => `Explain pre-operative care procedures and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "post-operative care": (originalInput) => `Offer insights on post-operative care, recovery, and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "sterilization techniques": (originalInput) => `Provide information on sterilization techniques used in operation theaters related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical procedures": (originalInput) => `Explain various surgical procedures, their purposes, and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "emergency surgery": (originalInput) => `Offer insights on emergency surgery procedures and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "minimally invasive surgery": (originalInput) => `Provide information on minimally invasive surgery techniques and their benefits related to ${originalInput}. Ensure practical and accurate details.`,
    "robotic surgery": (originalInput) => `Explain robotic surgery, its advantages, and related ${originalInput}. Ensure practical and accurate details.`,
    "surgical safety": (originalInput) => `Provide detailed information on surgical safety protocols and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "patient positioning": (originalInput) => `Offer insights on patient positioning techniques and considerations during surgery related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical team roles": (originalInput) => `Explain the roles and responsibilities of the surgical team related to ${originalInput}. Ensure practical and accurate details.`,
    "infection control": (originalInput) => `Provide information on infection control measures in operation theaters related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical complications": (originalInput) => `Explain common surgical complications and their management related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical outcomes": (originalInput) => `Offer insights on expected surgical outcomes and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "recovery time": (originalInput) => `Provide information on recovery times for various surgeries related to ${originalInput}. Ensure practical and accurate details.`,
    "post-surgical pain management": (originalInput) => `Explain post-surgical pain management techniques and considerations related to ${originalInput}. Ensure practical and accurate details.`,
    "surgical site infections": (originalInput) => `Offer insights on the prevention and management of surgical site infections related to ${originalInput}. Ensure practical and accurate details.`,
    
    // Add more keywords and prompts as needed...
  };
  
  export const processUserInput = (input) => {
    // Split the input into words
    const words = input.toLowerCase().split(' ');
  
    // Check for keywords
    for (const word of words) {
      // Combine adjacent words to check for multi-word keywords
      const combinedWords = [word, words[words.indexOf(word) + 1]].join(' ');
  
      if (keywordPrompts[word]) {
        // If a single-word keyword is found, construct the new input with the original input included
        const newPrompt = keywordPrompts[word](input);
        return { processedInput: newPrompt, isModified: true };
      } else if (keywordPrompts[combinedWords]) {
        // If a multi-word keyword is found, construct the new input with the original input included
        const newPrompt = keywordPrompts[combinedWords](input);
        return { processedInput: newPrompt, isModified: true };
      }
    }
  
    // If no keywords are found, check if the input is unrelated to operation theater
    const operationTheaterKeywords = [
      "surgery", "operation room", "anesthesia", "surgical instruments", "pre-operative care", 
      "post-operative care", "sterilization techniques", "surgical procedures", "emergency surgery", 
      "minimally invasive surgery", "robotic surgery", "surgical safety", "patient positioning", 
      "surgical team roles", "infection control", "surgical complications", "surgical outcomes", 
      "recovery time", "post-surgical pain management", "surgical site infections"
    ];
      
    const inputWords = input.toLowerCase().split(' ');
    console.log("Hello from Operation Theater ");
    const isOperationTheaterRelated = inputWords.some(word => operationTheaterKeywords.includes(word));
  
    if (!isOperationTheaterRelated) {
      return { processedInput: `I'm sorry, I cannot provide information on that topic. Please ask operation theater-related queries. ${input}`, isModified: true };
    }
  
    // If no keywords are found, append the generic message
    const newPrompt = `Provide accurate and practical operation theater information related to the query. Ensure the details are proper and free from dramatic claims. ${input}`;
    return { processedInput: newPrompt, isModified: true };
  };
  