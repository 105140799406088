import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import HealthcareIcon from '../../images/Health.png';
import ManufacturingIcon from '../../images/Manuf.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../components/Authentication/AuthContext';
import PharmaBot from '../../components/HealthCare/Pharmaceutical/Pharmaceutical';
import OperationTheaterBot from '../../components/HealthCare/OperationTheater/OperationTheater'; // Import Operation Theater Bot
import ManufacturingBot from '../../components/Manufacturing/ManufacturingBot';
import HealthcareBot from "../../components/HealthCare/General/HealthcareBot";
import '../../styles/styles.css';
import pharmaImg from '../../images/PharmaLogo1.png';
import operationTheaterImg from '../../images/OperationTheaterLogo1.png'; // Import Operation Theater Image
import HealthcareImg from '../../images/HealthCareLogo1.png';

const LandingPage = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      console.log("is not authenticated");
      navigate("/signin");
    }
  }, [isAuthenticated, navigate]);

  const handleFlexClick = (tabName) => {
    setSelectedTab(tabName);
    setSelectedCard(null);
  };

  const handleCardClick = (cardName) => {
    setSelectedCard(cardName);
  };

  const cards = [
    { id: "HealthcareB", title: "Healthcare Bot", image: HealthcareImg },
    { id: "Pharmaceutical", title: "Pharmaceutical Bot", image: pharmaImg },
    { id: "OperationTheater", title: "Operation Theater", image: operationTheaterImg },
    // Add other cards as needed
  ];

  return (
    <div>
      <div style={{ display: 'flex', height: '100vh' }}>
        <div style={{
          position: 'sticky',
          top: '0',
          width: '300px',
          backgroundImage: 'linear-gradient(to right, white, lightblue)',
          borderRadius: '10px'
        }} className="d-flex flex-column p-2 bg-body-tertiary homepage">
          <ul className="nav nav-pills flex-column mb-auto">
            <li style={{ fontSize: "15px", marginTop: "10px", color: "black" }}>
              <a onClick={() => handleFlexClick("Healthcare")} className={`nav-link ${selectedTab === "Healthcare" ? 'active' : ''}`} aria-current="page">
                <img src={HealthcareIcon} alt="Healthcare Icon" className="bi pe-none me-2" width="30" height="30" />
                <span className="custom-font">Healthcare</span>
              </a>
            </li>
            <li className="nav-item" style={{ fontSize: "20px", marginTop: "5px", color: "black" }}>
              <a onClick={() => handleFlexClick("Manufacturing")} className={`nav-link ${selectedTab === "Manufacturing" ? 'active' : ''}`} aria-current="page">
                <img src={ManufacturingIcon} alt="Manufacturing Icon" className="bi pe-none me-2" width="30" height="30" />
                <span className="custom-font">Manufacturer</span>
              </a>
            </li>
          </ul>
          <hr />
        </div>
        <div style={{ flex: 1 }}>
          {selectedTab === "Healthcare" && selectedCard === null && (
            <div style={{ padding: '10px', margin: '20px' }}>
              <h1>Select our services inside Healthcare</h1>
              <div className="d-flex flex-wrap justify-content-around">
                {cards.map((card) => (
                  <Card key={card.id} style={{ width: '100%', maxWidth: '300px', margin: '10px', cursor: 'pointer',padding:'10px' , alignItems:'center', display:'grid'}} onClick={() => handleCardClick(card.id)}>
                    <div className="d-flex" style={{alignItems:'center'}}>
                      <div style={{ marginRight: '10px' }}>
                        <Card.Img variant="top" src={card.image} alt={card.title} style={{ display: 'block', objectFit: 'cover', maxWidth: '100px' }} />
                      </div>
                      <div>
                        <Card.Body style={{padding: '10px'}}>
                          <Card.Title>{card.title}</Card.Title>
                        </Card.Body>
                      </div>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          )}
          {selectedTab === "Healthcare" && selectedCard === "HealthcareB" && <HealthcareBot />}
          {selectedTab === "Healthcare" && selectedCard === "Pharmaceutical" && <PharmaBot />}
          {selectedTab === "Healthcare" && selectedCard === "OperationTheater" && <OperationTheaterBot />}
          {selectedTab === "Manufacturing" && <ManufacturingBot />}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
