import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { IoIosCloseCircle } from "react-icons/io";
import TypingIndicator from '../TypingIndicator/typingindicator';
import trainingData from '../../assets/TrainingData/trainingData.json'; // Check the path and adjust if necessary
import '../../styles/ChatApp.css'
import botLogo from '../../images/newLogo.png';
import onlinelogo from '../../images/onlinelogo.png';
import { LuSendHorizonal } from "react-icons/lu";

const ChatApp = ({ setShowChat }) => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState(() => {
    const storedMessages = sessionStorage.getItem('chatMessages');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (!messages.some((msg) => msg.role === 'assistant' && msg.content === 'Welcome to MeBot!')) {
      const delayedWelcomeMessage = setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: 'assistant', content: 'Welcome to MeBot!' }
        ]);
      }, 1000);
  
      return () => clearTimeout(delayedWelcomeMessage);
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem('chatMessages', JSON.stringify(messages));
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputValue.trim()) {
      console.error('Input value is empty');
      return;
    }
    setLoading(true);

    // Check if user input matches any keyword in the training data
    const matchedKeyword = trainingData.find(
      (item) => item.input.toLowerCase() === inputValue.toLowerCase()
    );
    if (matchedKeyword) {
      setMessages([
        ...messages,
        { role: 'user', content: inputValue },
        { role: 'assistant', content: matchedKeyword.response }
      ]);
      setInputValue('');
      setLoading(false);
    } else {
      // If no keyword match, fetch data from the API
      fetchDataFromAPI(inputValue);
    }
  };

  const fetchDataFromAPI = async (input) => {
    let data = {
      input: {
        task: `suppose you are a personal AI MEBOT and answer for the query "${input}`
      },
      config: {},
      kwargs: {}
    };
    console.log(process.env.REACT_APP_API_MEBOT_URL);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_API_MEBOT_URL, 
      //url: 'http://127.0.0.1:2000/chain/invoke',
      //url: 'http://3.134.54.81:2000/chain/invoke',
      //url: 'http://api.mebot.live/chain/invoke',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    };
    try {
      const response = await axios.request(config);
      var parts = response.data.output.split(':');
      var extractedData = parts.slice(2).join(':').trim();
      if(extractedData.length==0){
        var parts1 = response.data.output.split('\n');
        var extractedData1 = parts1.slice(2).join(':').trim();
        //console.log("IF PART");
        setMessages([
          ...messages,
          { role: 'user', content: input },
          { role: 'assistant', content: extractedData1 }
        ]);
      }
      else{
        //console.log("ELSE PART");
        setMessages([
          ...messages,
          { role: 'user', content: input },
          { role: 'assistant', content: extractedData }
        ]);
      }
      setInputValue('');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="chat-app">
      <div id="Botheader">
        <div className="header-left">
          <img src={botLogo} alt="Bot Logo" className="bot-logo" />
          <div className="company">
            <div className="header" style={{ fontSize: '20px', marginLeft: '10px'}}>MeBot</div>
            <div className="status">
              <span> <img src={onlinelogo} alt="online Logo" style={{ width: '10px', height: '10px'}}  /> </span>
              Online
            </div>
          </div>
        </div>
        <div className="header-right">
          <div className="close" onClick={() => setShowChat(false)}>
            <IoIosCloseCircle className="close-icon" style={{ color: 'black', height: '20px', width: '20px' }} />
          </div>
        </div>
      </div>

      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            {message.role === 'user' ? (
              <div className="message-header message-right">You :</div>
            ) : (
              <div className="message-header message-left">Bot :</div>
            )}
            {message.role === 'user' ? (
              <div className="user-message message-right" style={{ backgroundColor: 'gray'}}>{message.content}</div>
            ) : (
              <div className="assistant-message message-left">{message.content}</div>
            )}
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div>
        {loading && <TypingIndicator />}
        <form onSubmit={handleSubmit} className="input-form">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Type your message..."
            className="input-field"
            autoFocus
          />
          <button type="submit" className="send-button">
          <LuSendHorizonal />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatApp;
