// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/AboutUs.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh; /* Adjust this as needed */
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-up-form {
    background-color: rgba(255, 255, 255, 0.5); /* White color with 50% opacity */
    border-radius: 25px;
}

.image-container {
    max-width: 100%; /* Set maximum width */
    max-height: 100%; /* Set maximum height */
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-control{
    border-radius: 25px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.input-icon {
    margin: 20px; /* Adjust the value as needed */
    font-size: calc(5px + 1vw) ;
}
.google-sign-in-button {
    background-color: #fff;
    border-color: #ccc;
    color: #000;
    border-radius: 25px;
}

.google-sign-in-button:hover {
    background-color: #323232;
}

/* Footer.css */


@media screen and (max-width: 768px) {
  .image-container {
    max-width: 34%; /* Reduce maximum width for smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/signup.css"],"names":[],"mappings":"AAAA;IACI,yDAA8C;IAC9C,sBAAsB;IACtB,4BAA4B;IAC5B,aAAa,EAAE,0BAA0B;IACzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,0CAA0C,EAAE,iCAAiC;IAC7E,mBAAmB;AACvB;;AAEA;IACI,eAAe,EAAE,sBAAsB;IACvC,gBAAgB,EAAE,uBAAuB;IACzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,mJAAmJ;AACvJ;;AAEA;IACI,YAAY,EAAE,+BAA+B;IAC7C,2BAA2B;AAC/B;AACA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,eAAe;;;AAGf;EACE;IACE,cAAc,EAAE,6CAA6C;EAC/D;AACF","sourcesContent":[".background-image {\n    background-image: url('../images/AboutUs.png');\n    background-size: cover;\n    background-repeat: no-repeat;\n    height: 100vh; /* Adjust this as needed */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.sign-up-form {\n    background-color: rgba(255, 255, 255, 0.5); /* White color with 50% opacity */\n    border-radius: 25px;\n}\n\n.image-container {\n    max-width: 100%; /* Set maximum width */\n    max-height: 100%; /* Set maximum height */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.form-control{\n    border-radius: 25px;\n    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n\n.input-icon {\n    margin: 20px; /* Adjust the value as needed */\n    font-size: calc(5px + 1vw) ;\n}\n.google-sign-in-button {\n    background-color: #fff;\n    border-color: #ccc;\n    color: #000;\n    border-radius: 25px;\n}\n\n.google-sign-in-button:hover {\n    background-color: #323232;\n}\n\n/* Footer.css */\n\n\n@media screen and (max-width: 768px) {\n  .image-container {\n    max-width: 34%; /* Reduce maximum width for smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
