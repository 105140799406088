// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typing-indicator {
    display: flex;
    align-items: center;
    padding-top: 0.6rem;
  }
  
  .dot-1, .dot-2, .dot-3 {
    background-color: #007bff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    animation: typing 1.5s infinite;
  }
  
  .dot-2 {
    animation-delay: 0.5s;
  }
  
  .dot-3 {
    animation-delay: 1s;
  }
  
  @keyframes typing {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/typing.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,+BAA+B;EACjC;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE;MACE,YAAY;IACd;IACA;MACE,UAAU;IACZ;IACA;MACE,YAAY;IACd;EACF","sourcesContent":[".typing-indicator {\n    display: flex;\n    align-items: center;\n    padding-top: 0.6rem;\n  }\n  \n  .dot-1, .dot-2, .dot-3 {\n    background-color: #007bff;\n    border-radius: 50%;\n    width: 10px;\n    height: 10px;\n    margin-right: 5px;\n    animation: typing 1.5s infinite;\n  }\n  \n  .dot-2 {\n    animation-delay: 0.5s;\n  }\n  \n  .dot-3 {\n    animation-delay: 1s;\n  }\n  \n  @keyframes typing {\n    0% {\n      opacity: 0.5;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0.5;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
